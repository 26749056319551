import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

// material-ui
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  AssistantDirectionOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
// project imports
import NavItem from "../NavItem";
import {
  DashboardIcon,
  MyProfileIcon,
  OrdersIcon,
  SettingsIcon,
} from "uploads";

// assets
// import { IconChevronDown, IconChevronUp } from "@tabler/icons";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }: any) => {
  // const customization = useSelector((state: any) => state.customization);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const { pathname } = useLocation();
  const checkOpenForParent = (child: any, id: any) => {
    child.forEach((item: any) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children?.length > 0) {
      menu.children.forEach((item: any) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        const currentIndex = pathname
          .toString()
          .split("/")
          .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  // menu collapse & item
  const menus = menu.children?.map((item: any) => {
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  const getDrawerIcons = (type: any) => {
    try {
      switch (type) {
        case "dashboard":
          return (
            <ListItemIcon>
              <DashboardIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "returns_":
          return (
            <ListItemIcon>
              <OrdersIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "all-orders":
          return (
            <ListItemIcon>
              <OrdersIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
          case "payments":
            return (
              <ListItemIcon>
                <OrdersIcon height={25} width={25} stroke_width={"4px"} />
              </ListItemIcon>
            );
        case "myProfile_":
          return (
            <ListItemIcon>
              <MyProfileIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
        case "settings_":
          return (
            <ListItemIcon>
              <SettingsIcon height={25} width={25} stroke_width={"4px"} />
            </ListItemIcon>
          );
          case "chats_":
            return (
              <ListItemIcon>
                <SettingsIcon height={25} width={25} stroke_width={"4px"} />
              </ListItemIcon>
            );

        default:
          break;
      }
    } catch (error) {}
  };
  // SupportIcon
  return (
    <>
      <ListItemButton
        sx={{
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: `${level * 5}px`, //level > 1 ? 1 : 1.25,
          pl: 1,
          borderRadius: 1,
        }}
        onClick={handleClick}
      >
        {/* ICON */}
        <ListItemIcon sx={{ my: "auto", minWidth: !menu?.icon ? 18 : 36 }}>
          {getDrawerIcons(menu.id)}
        </ListItemIcon>

        <p style={{ marginBottom: 0, flex: 1 }}>{menu.title}</p>

        {open ? (
          <ExpandLess style={{ marginTop: "auto", marginBottom: "auto" }} />
        ) : (
          <ExpandMore style={{ marginTop: "auto", marginBottom: "auto" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
