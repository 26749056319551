import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { ListItemButton, ListItemIcon, useMediaQuery } from "@mui/material";

// project imports

// assets
import { DashboardOutlined, Support } from "@mui/icons-material";
import { RootState } from "store";
import { handleClose, handleMenuClick } from "store/drawerReducer";
import {
  DashboardIcon,
  EarningsIcon,
  HelpIcon,
  MyProfileIcon,
  OrdersIcon,
  ReturnOrders,
  SettingsIcon,
} from "uploads";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state: RootState) => state.drawer);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  let itemTarget = "_self";

  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref as any} {...props} to={item.url} target={itemTarget} />
    )),
    href: "",
    target: null,
  };

  if (item?.external) {
    listItemProps = {
      component: "a" as any,
      href: item.url as any,
      target: itemTarget as any,
    };
  }

  const itemHandler = (id: any) => {
    dispatch(handleMenuClick(id));

    if (matchesSM) dispatch(handleClose());
  };

  // active menu item on page load
  const url = window.location.pathname.split("/").pop();

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(handleMenuClick(item.id));
    }
  }, [url]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: "flex-start",
        // py: level > 1 ? 1 : 1.25,
        py:
          item.id === "dashboard" ||
          item.id === "_settings" ||
          item.id === "about" ||
          item.id === "orders" ||
          item.id === "returns" ||
          item.id === "settings" ||
          item.id === "payments" ||
          item.id === "support-chat" ||
          item.id === "profile"
            ? `${level * 5}px`
            : `${level * 3}px`,
        // : 1, //level > 1 ? 1 : 1.25,
        pl:
          item.id === "dashboard" ||
          item.id === "_settings" ||
          item.id === "about" ||
          item.id === "orders" ||
          item.id === "returns" ||
          item.id === "settings" ||
          item.id === "payments" ||
          item.id === "support-chat" ||
          item.id === "profile"
            ? 1
            : `${level * 20}px`,
        // : `${level * 20}px`,
      }}
      selected={
        customization.isOpen.findIndex((id: any) => id === item.id) > -1
      }
      onClick={() => itemHandler(item.id)}
    >
      {/* ICON */}

      {item.id === "dashboard" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <DashboardIcon height={20} width={20} stroke_width={5} />
        </ListItemIcon>
      ) : item.id === "settings" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <SettingsIcon height={20} width={20} stroke_width={2} />
        </ListItemIcon>
      ) : item.id === "about" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <HelpIcon height={20} width={20} stroke_width={2} />
        </ListItemIcon>
      ) : item.id === "orders" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <OrdersIcon height={20} width={20} stroke_width={2} />
        </ListItemIcon>
      ) : item.id === "returns" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <OrdersIcon height={20} width={20} stroke_width={2} />
        </ListItemIcon>
      ) : item.id === "settings_" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <SettingsIcon height={20} width={20} stroke_width={2} />
        </ListItemIcon>
      ) : item.id === "profile" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <MyProfileIcon height={20} width={20} stroke_width={2} />
        </ListItemIcon>
      ) : item.id === "payments" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <EarningsIcon height={20} width={20} stroke_width={2} />
        </ListItemIcon>
      ) : item.id === "support-chat" ? (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          <Support />
        </ListItemIcon>
      ) : null}
      <p
        className="marginLeft"
        style={{ marginBottom: 0, marginLeft: `10px !important` }}
      >
        {item.title}
      </p>
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
