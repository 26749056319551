import React from "react";
import { Login } from "./PageAsync";

export default function AuthenticationRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    children: [
      {
        path: "/",
        element: <Login classes={classes} />,
      },
      {
        path: "/login",
        element: <Login classes={classes} />,
      },
      {
        path: "/verify-account/:token",
        element: <Login classes={classes} />,
      },
      {
        path: "/*",
        element: <Login classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}
